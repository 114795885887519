.home {
  display: flex;
  flex-direction: column;
  padding: 25px;
  padding-bottom: 0;
  /* overflow-y: hidden; */
}

.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height: -webkit-fill-available; */
  background-color: #fff;
  gap: 50px;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 20px 0px;
  margin-bottom: 0px;
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
}

@media screen and (min-width: 1000px) {
  .table {
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  .home {
    padding: 10px;
    align-items: center;
    /* overflow-y: auto; */
  }

  .table {
    padding: 20px 10px;
    height: 100%;
  }
}
