.approval_action_button_approve {
  background-color: #596c91;
  color: #fff;
  border: 1px solid #596c91;
}

.approval_action_button_decline {
  background-color: #fff;
  color: #e80000;
  border: 1px solid #e80000;
}

.approval_action_button {
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  display: flex;
  align-items: center;
  /* width: 120px; */
  padding: 0px 20px;
  justify-content: center;
}
