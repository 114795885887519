.referral_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

@media screen and (max-width: 700px) {
  .referral_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

.referralCard {
  background-color: white;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.referralCard > div {
  width: 100%;
  /* background-color: aqua; */
}

.referralCard .top {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* padding: 50px; */
  margin: 50px;
  margin-bottom: -40px;
  width: calc(100% - 100px);

  @media screen and (max-width: 600px) {
    margin: 20px;
    margin-bottom: -20px;
    width: calc(100% - 40px);
  }

  .title {
    font-family: Nunito;
    font-size: 20px;
    font-weight: 600;
    color: #fa7b42;
  }
}
.referralCard .top .topBox {
  display: flex;
  color: rgb(0, 44, 165);
  width: 100%;
  .leftBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    .total {
      font-family: Nunito;
      font-size: 20px;
      font-weight: 600;
    }
    .totalCount {
      font-family: Nunito;
      font-size: 30px;
      font-weight: 700;
      color: #fa7b42;
    }

    @media screen and (max-width: 600px) {
      width: 200px;
      .total {
        font-size: 16px;
      }
      .totalCount {
        font-size: 25px;
      }
    }
  }

  .rightBox {
    display: flex;
    flex: 1;
    /* background-color: rgb(72, 95, 87); */
    justify-content: center;
    align-items: end;

    @media screen and (max-width: 600px) {
      img {
        width: 275px;
      }
    }
  }
}
.referralCard .middle {
  width: 100%;
  background: #fa7b42;
  height: 100px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }
  .leftBox {
    display: flex;
    flex-direction: column;
    width: 70%;
    .text1 {
      font-family: Nunito;
      font-size: 24px;
      font-weight: 600;
      color: white;
    }
    .text2 {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    @media screen and (max-width: 600px) {
      .text1 {
        font-size: 16px;
      }
      .text2 {
        font-size: 12px;
      }
    }
  }
  .rightBox {
    position: relative;
    top: -30px;
    width: 200px;
    /* height: auto; */
    .parellelogram {
      width: 150px;
      height: 65px;
      position: absolute;
      border-radius: 20px;
    }
    .parellelogram_back {
      background: #0073be;
      transform: skew(-35deg, -10deg);

      /* transform: rotateX("13.15deg"); */
    }
    .parellelogramfront {
      background: #fff500;
      transform: skew(-25deg, 0deg);
    }
    .parellelogram_content {
      display: flex;
      position: absolute;
      width: 150px;
      height: 65px;
      justify-content: center;
      align-items: center;
      /* z-index: 1; */
      .rewardAmount {
        font-family: Helvetica;
        font-size: 35px;
        font-weight: 700;
      }
      .rewardSupportingTest {
        .boldContent {
          font-family: Nunito;
          font-size: 20px;
          font-weight: 600;
          /* line-height: 0px; */
        }
        .softContent {
          font-family: Nunito;
          font-size: 12px;
          font-weight: 700;
          line-height: 10px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      .parellelogram {
        width: 120px;
        height: 50px;
      }
      .parellelogram_content {
        width: 120px;
        height: 50px;
        .rewardAmount {
          font-size: 30px;
        }
        .rewardSupportingTest {
          .boldContent {
            font-size: 16px;
          }
          .softContent {
            font-size: 10px;
          }
        }
      }
    }
  }
}
.referralCard .bottom {
  /* padding: 50px; */
  /* padding-top: 0px; */
  .content {
    margin: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .supportingText {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
    }
    .box {
      display: flex;
      .uniqueCode {
        height: 50px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fa7b42;
        /* border-radius: 20px; */
        border-radius: 20px 0px 0px 20px;
      }
      .copyButton {
        height: 52px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fa7b42;
        background-color: #fa7b42;
        color: white;
        border-radius: 0px 20px 20px 0px;
        /* margin-left: 20px; */
        cursor: pointer;
        position: relative;
        top: -1px;
      }
    }
  }
}
