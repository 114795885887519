.admin_tenants_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

@media screen and (max-width: 700px) {
  .admin_tenants_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

.admin_tenants_content_bottomSpace {
  height: calc(100% - 200px);
}

.tenants_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #f2f2f2; */
  gap: 40px;
}

.rentStatusPaid,
.rentStatusPending {
  margin: 0px;
  /* font-size: larger; */
  font-weight: 600;
}

.rentStatusPaid {
  color: #00b428;
}

.rentStatusPending {
  color: #e80000;
}

.filter_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.filter_section img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.filter_popup_container {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 170px;
  /* height: 300px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
}
.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filters p {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #534d4a;
  text-align: left;
}

.filter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.filter input {
  /* width: 100%; */
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px 10px;
}
