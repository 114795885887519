.notifications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: 90vh;
  margin: 25px;
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
}

.back_arrow{
    width: 27.001px;
    height: 15.935px;
    cursor: pointer;
}

.header{
    display: flex;
    gap: 10px;
    color: var(--neutral-neutral-01, #000);
    font-size: 18px;
    font-weight: 600;
    align-items: center;
}

.notification_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: calc(100% - 40px);
  height: 40px;
  border-radius: 10px;
  background: rgba(250, 123, 66, 0.12);
}

.content_wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:10px;
}

.title {
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
}

.content{
  color: var(--neutral-neutral-01, #000);
  font-size: 12px;
  font-weight: 500;
}

.button_wrap{
    display: flex;
    gap: 20px;
    
}

.button_wrap button{
    background: none;
    color: #FA7B42;
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    border: none;
    cursor: pointer;
}
