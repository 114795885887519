.payments {
  padding: 25px;
  height: calc(100% - 105px);
  padding-bottom: 0;
  /* overflow-y: hidden; */
}

@media screen and (min-width: 1000px) {
  .payments {
    overflow-y: auto;
  }
}

.payments_header {
  display: flex;
  gap: 50px;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 600;
}

.payments_header:hover {
  cursor: pointer;
}

.active {
  border-bottom: 5px solid #fa7b42;
  padding-bottom: 5px;
}

.payment_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  overflow-y: auto;
  gap: 15px;
}

@media screen and (min-width: 600px) {
  .payment_content {
    height: -webkit-fill-available;
  }
}

.paymnetTableAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* margin-bottom: 20px; */
}
.paymnetTableAction .note {
  font-size: 12px;
  color: rgb(32, 33, 34);
}

@media (max-width: 600px) {
  .payments {
    padding: 5px;
    height: calc(100% - 90px);
    /* overflow-y: auto; */
  }

  .payment_content {
    /* width: calc(100vw - 105px); */
    padding: 20px 15px;
  }

  .paymnetTableAction {
    flex-direction: column;
  }
}

.extraMargin {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .centre_form > div {
    max-width: max-content;
  }
  .centre_form {
    justify-content: center;
  }
}
