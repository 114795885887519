.nav_bar_header {
  display: flex;
  gap: 50px;
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 600;
}

.nav_bar_header:hover {
  cursor: pointer;
}

.active {
  border-bottom: 5px solid #fa7b42;
  padding-bottom: 5px;
}

@media (max-width: 600px) {
  .nav_bar_header {
    gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
  }
}
