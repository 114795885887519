.admin_analytics_content_title {
    color: #FA7B42;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.admin_analytics_content {
    display: flex;
    flex-direction: column;
    /* Header - 80px, Padding top - 25px */
    padding: 15px;
    padding-bottom: 0px;
    margin: 25px;
    gap: 20px;
    margin-bottom: 0px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
}

.revenue_analytics_top_section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 10px 0px;
}

@media screen and (max-width: 700px) {
    .admin_analytics_content {
        padding: 25px 10px;
        width: calc(100% - 20px);
    }
}