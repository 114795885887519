.tenant {
  display: flex;
  flex-direction: row;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .tenant {
    height: 100dvh;
    overflow-y: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
