.action_button {
  background-color: #fa7b42;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  max-width: max-content;
}
