.icon_button {
  background-color: #fa7b42;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 25px;
}

.icon_button span {
  color: #fff;
  font-size: 14px;
}

.icon_button_whiteBg {
  background-color: #fff !important;
}
