/* SearchField.css */
.search_field {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 6px;
  border-radius: 6.667px;
  border: 0.667px solid #fa7b42;
  max-width: 230px;
  height: 25px;
  flex-shrink: 0;
}

@media screen and (max-width: 700px) {
  .search_field {
    max-width: 100%;
    width: calc(100% - 15px);
  }
}

.search_icon {
  padding: 6px;
  color: #999;
}

.search_input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}
