.stats_board {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  cursor: pointer;
}

.stat_board_title {
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.stats_board_stats_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 700px) {
  .spaceBetween {
    justify-content: space-between;
  }
}

.stat_board_single_stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
}

.stat_board_single_stat img {
  height: 30px;
  width: 30px;
}

.stat_board_single_stat_name {
  text-align: center;
  font-size: 14px;
  font-weight: 800;
}

.stat_board_single_stat_value {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 600px) {
  .stats_board {
    min-width: -webkit-fill-available;
    /* padding: 5px; */
  }

  .stats_board_stats_wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
