.admin_dashboard_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: calc(100% - 30px); */
  /* height: 60px; */
  background: var(--neutral-neutral-02, #fff);
  /* margin-bottom: 10px; */
  padding: 25px;
}

.admin_dashboard_header span {
  font-size: 20px;
  font-weight: 500;
}

.admin_dashboard_header_actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.admin_dashboard_header_actions img {
  cursor: pointer;
}

.profile {
  position: fixed;
  position: absolute;
  z-index: 20;
  right: 50px;
  top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #fa7b42;
  background: var(--neutral-neutral-02, #fff);
}

.profile_content {
  display: flex;
  gap: 10px;
  color: var(--neutral-neutral-01, #000);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.user_name {
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 400;
}
.collapse_button {
  display: none;
}

@media screen and (max-width: 1000px) {
  .user_name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .collapse_button {
    display: flex;
    width: 20px;
    height: 20px;
  }

  .nav_background {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
}

@media screen and (max-width: 600px) {
  .full_page_icon {
    display: none;
  }
}
