/* .admin_dashboard {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.header_mainContent {
  display: flex;100dvh
  flex-direction: column;
  width: 100%;
} */
.admin_dashboard {
  display: flex;
  flex-direction: row;
  height: 100%;
}

@media screen and (max-width: 1000px) {
  .admin_dashboard {
    height: 100dvh;
    overflow-y: auto;
  }
}

.header_mainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}
