/* .admin_dashboard {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.admin_dashboard_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

.admin_dashboard_content_row {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.admin_dashboard_content_stats_column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.stats_table_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.stats_table_header span {
  font-size: 16px;
  font-weight: 500;
  color: #fa7b42;
}

.stats_table_header span:hover {
  cursor: pointer;
}

.rentStatusPaid,
.rentStatusPending {
  margin: 0px;
  font-weight: 600;
}

.rentStatusPaid {
  color: #00b428;
}

.rentStatusPending {
  color: #e80000;
} */

.admin_dashboard {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.admin_dashboard_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

@media screen and (max-width: 700px) {
  .admin_dashboard_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}

.admin_dashboard_content_row {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

@media screen and (max-width: 700px) {
  .admin_dashboard_content_row {
    flex-direction: column;
  }
}

.admin_dashboard_content_stats_column {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.stats_table_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  /* margin: 10px; */
}

.stats_table_header span {
  font-size: 16px;
  font-weight: 500;
  color: #fa7b42;
}

.stats_table_header span:hover {
  cursor: pointer;
}

.rentStatusPaid,
.rentStatusPending {
  margin: 0px;
  /* font-size: larger; */
  font-weight: 600;
}

.rentStatusPaid {
  color: #00b428;
}

.rentStatusPending {
  color: #fc9b9b;
}
