.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: fixed;
  z-index: 30;
  top: 0;
  /* margin-left: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  max-width: 700px;
  height: 100dvh;
  gap: 20px;
}

.backdrop_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  width: calc(100% - 40px);
  height: 80px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
}

@media screen and (max-width: 600px) {
  .modal {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }

  .backdrop_header {
    flex-direction: column;
    height: max-content;
    padding: 10px;
    margin-bottom: 10px;
  }
}

.body {
  width: 100%;
  height: 80vh;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  overflow-y: auto;
}

.fileviewer_backdrop_img {
  max-width: 100%;
}

.backdrop_img {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--neutral-neutral-01, #000);
  /* font-size: 20px; */
  font-weight: 500;
}

.backdrop_img input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.submit_button {
  background-color: #fa7b42;
  text-align: right;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.back_arrow {
  width: 24.727px;
  height: 16.113px;
  cursor: pointer;
}

.modalCloser {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: #fa7b42;
  font-size: 25px;
  font-weight: 900;
}

.referralBottom {
  /* padding-top: 0px; */
  position: relative;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: white;
    padding: 50px;
    border-radius: 10px;

    .supportingText {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 600;
    }
    .box {
      display: flex;
      .uniqueCode {
        height: 50px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fa7b42;
        /* border-radius: 20px; */
        border-radius: 20px 0px 0px 20px;
        outline: none;
        padding: 0px 10px;
        font-size: 15px;
        text-align: center;
      }
      .copyButton {
        height: 52px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fa7b42;
        background-color: #fa7b42;
        color: white;
        border-radius: 0px 20px 20px 0px;
        /* margin-left: 20px; */
        cursor: pointer;
        position: relative;
        top: -1px;
      }
    }
  }
}
