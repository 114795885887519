.analytics_item {
    display: flex;
    flex-direction: row;
    max-width: max-content;
    align-items: center;
}

.analytics_item_values_wrapping {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    padding-left: 10px;
}

.analytics_item_value {
    color: #000;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.analytics_item_text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}