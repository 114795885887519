.admin_home_sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  max-width: 210px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.admin_home_sidebar_collpased {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  max-width: 70px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.admin_home_sidebar_mobile {
  display: none;
}

.loyal_partners_logo {
  width: 145.265px;
  height: 60px;
  padding: 20px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.loyal_partners_logo_icon {
  width: 60px;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  align-self: flex-start;
  padding-bottom: 30px;
  padding-top: 5px;
  padding-left: 5px;
}

.admin_dashboard_wrapper,
.admin_dashboard_wrapper_collapsed {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  /* justify-content: space-evenly; */
  height: 100%;
  /* gap: 10px; */
  align-self: center;
  width: 100%;
}

.dashboard_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: calc(100% - 25px);
  min-height: 40px;
  padding-left: 20px;
  cursor: pointer;
}

.dashboard_section:last-child {
  /* align-self: flex-end; */
  margin-top: auto;
  margin-bottom: 15px;
}

.dashboard_section span {
  color: var(--neutral-neutral-01, #000);
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.dashboard_section_active {
  background-color: #ffeae0;
  border-left: 5px solid #fa7b42;
}

.dashboard_section_subsection {
  padding-left: 30px;
  width: -webkit-fill-available;
}

.navbar_collapse_button {
  display: flex;
}

.dashboard_sidebar_bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  padding-left: 20px;
  min-width: 210px;
  height: 10%;
  margin-top: 3px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.navbar_mobile_view {
  display: none;
}

.admin_navbar_expand_collapse_icon {
  margin-left: 40px;
}

@media screen and (max-width: 1000px) {
  .admin_home_sidebar {
    display: none;
  }

  .admin_home_sidebar_collpased {
    display: none;
  }

  .admin_home_sidebar_mobile {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-width: 70%;  */
    height: calc(100% - 20px);
    padding-top: 20px;
    max-width: 210px;
    background: var(--neutral-neutral-02, #fff);
    box-shadow: 5px 4px 20px 0px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
}

@media screen and (max-width: 500px) {
  .admin_home_sidebar_mobile {
    min-width: 70%;
  }
}
