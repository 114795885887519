.persoal_details {
  margin: 25px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  padding: 20px 0 20px 20px;
  margin-bottom: 0;
}

@media screen and (min-width: 700px) {
  .persoal_details {
    overflow-y: auto;
  }
}

.personal_details_wrapper {
  overflow-y: auto;
}

.extra_margin {
  margin-top: 20px;
}
@media (max-width: 600px) {
  .persoal_details {
    margin: 5px;
    padding: 20px 10px;
  }
}
