.add_employee {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
}

@media screen and (min-width: 700px) {
  .add_employee {
    height: 100%;
    overflow-y: auto;
  }
}

.section_header {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 600;
}

.section_header img {
  width: 27.001px;
  height: 15.935px;
  cursor: pointer;
}

.submit_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit_button {
  max-width: 234px;
  color: #fff;
  border-radius: 3.636px;
  background: #fa7b42;
  border: none;
  padding: 15px 60px;
  cursor: pointer;
}

.content_wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
