.main {
  display: flex;
  flex-direction: column;
  margin: 25px;
  margin-bottom: 0px;
  padding: 25px;
  overflow-y: hidden;
  background-color: #ffffff;

  box-shadow: 5px 4px 20px 0px #fa7b420f;

  overflow: auto;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.header .title {
  color: #000000;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.body {
  display: flex;
  gap: 50px;
  justify-content: space-around;
  margin-bottom: 20px;
  align-items: center;
}

/* .profileImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 100px;
} */
.profileImageContainer img {
  /* max-width: 200px; */
  /* border-radius: 10px; */
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: centre;
  gap: 20px;
  align-items: center;
  margin-top: 50px;
}

.footer .changePassword {
  color: #fa7b42;
  cursor: pointer;
  outline: none;
  /* border: none; */
  border: 1px solid #fa7b42;
  background: none;
  border-radius: 8px;
  padding: 5px 20px;

  display: flex;
  align-items: center;
  gap: 40px;
}

/* .table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: -webkit-fill-available;
  background-color: #fff;
  gap: 50px;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 20px 0px;
  margin-bottom: 0px;
  overflow-y: auto;
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
} */

@media (max-width: 600px) {
  .main {
    padding: 10px;
    align-items: center;
    overflow-y: auto;
  }

  /* .table {
    padding: 20px 10px;
    height: 100%;
  } */
}
