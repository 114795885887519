.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.75);

  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  z-index: 30;
  top: 0;
  /* margin-left: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  max-width: 700px;
  height: 100dvh;
  gap: 20px;
}

@media screen and (max-width: 600px) {
  .modal {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}

.backdrop_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  width: calc(100% - 40px);
  height: 80px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
}

.body {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
}

.fileviewer_backdrop_img {
  max-width: 100%;
}

.backdrop_img {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 500;
}

.send_contact {
  color: #fa7b42;
  text-align: right;
  font-size: 20px;
  font-weight: 500;

  cursor: pointer;
}

.back_arrow {
  width: 24.727px;
  height: 16.113px;
  cursor: pointer;
}
