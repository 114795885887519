.admin_employees_content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  /* Header - 80px, Padding top - 25px */
  height: calc(100% - 105px);
  padding: 25px;
  gap: 20px;
  padding-bottom: 0px;
}

.employees_stats_table_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: #f2f2f2; */
  gap: 40px;
}

@media screen and (max-width: 700px) {
  .admin_employees_content {
    padding: 25px 10px;
    width: calc(100% - 20px);
  }
}
