.App {
  text-align: center;
  height: 100dvh;
  border-radius: 10px;
  background-color: rgba(250, 123, 66, 0.2);
}

@media screen and (max-width: 1000px) {
  .App {
    height: 100%;
    border-radius: 0px;
  }
}
