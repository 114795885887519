.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
}

@media screen and (min-width: 1000px) {
  .body {
    overflow-y: auto;
  }
}

.details_header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 600;
  justify-content: space-between;
  flex-wrap: wrap;

  /* margin-bottom: 20px; */
}

.detailed_section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details_header img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.action_buttons {
  display: flex;
  gap: 20px;
}
.action_buttons img {
  height: 20px;
  cursor: pointer;
}

.paymentButtonGroup {
  display: flex;
  align-items: center;
  gap: 10px;

  flex-wrap: wrap;
}

@media screen and (max-width: 360px) {
  .paymentButtonGroup {
    width: 100%;
    justify-content: space-between;
  }
}
