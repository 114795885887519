.image_verification {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 10px;
}

.verification_header {
  color: var(--neutral-neutral-01, #000);
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px;
  margin-left: 0px;
}

@media screen and (max-width: 700px) {
  .verification_header {
    font-size: 16px;
  }
}

.button_wrap {
  display: flex;
  color: #fa7b42;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 30px;
}

.button_wrap span {
  color: #fa7b42;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.img_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  row-gap: 0px;
  padding: 10px;
  max-width: max-content;
  border-radius: 10px;
  border: 1px solid rgba(250, 123, 66, 0.5);
}

.img_wrap p {
  margin: 0;
}

@media (max-width: 600px) {
  .image_verification {
    margin-left: 0px;
  }

  .img_wrap {
    flex-direction: column;
    min-width: -webkit-fill-available;
  }
}
