.tenant_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* height: 100%; */
  padding: 20px;
  border-radius: 10px;
  background: var(--neutral-neutral-02, #fff);
  box-shadow: 5px 4px 20px 0px rgba(250, 123, 66, 0.06);
  gap: 20px;
}

@media screen and (min-width: 1000px) {
  .tenant_details {
    overflow-y: auto;
  }
}

.details_header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 20px;
  font-weight: 600;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}

.detailed_section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details_header img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.paymentButtonGroup {
  display: flex;
  align-items: center;
  gap: 10px;

  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .paymentButtonGroup {
    width: 100%;
    justify-content: space-between;
  }
}

.table_header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  color: var(--neutral-neutral-01, #000);
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom: 20px; */
}

.table_header img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.tenant_action_buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.tenant_action_buttons button {
  /* padding: 10px 20px; */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  transition: all 0.3s ease;
  outline: none;

  background-color: #fff;
  color: tomato;
}

.primaryBackground {
  background-color: #fa7b42 !important;
}
.action_buttons {
  display: flex;
  gap: 20px;
}
.action_buttons img {
  height: 20px;
  cursor: pointer;
}
