body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: var(--neutral-neutral-01, #000);
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
}

/* Customize the scrollbar for all scrollable elements */
::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  /* Thumb color */
  background-color: rgba(250, 123, 66, 0.50);
  /* Rounded corners for the thumb */
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Track color */
}